import { computed, ref } from "vue"
import { useStore } from "vuex"
// import { marked } from 'marked'

import publicApi from "@/api/publicApi"
import { marked } from "marked"

import defaultImg from '@/assets/img/ESCUDO_FRAY.png'
import defaultHeaderImg from '@/assets/img/header_noimg.png'

const useAnuncios = () => {
    const store = useStore()
    const isLoading = ref(true)
    const anuncioDetalle = ref()

    const anunciosList = ref([])
    const inicioAnuncios = ref(0)
    const finAnuncios = ref(5)
    const totalAnuncios = ref(0)
    const primerAnuncio = ref(null)

    const scrollComponent = ref(null)


    const anunciosHome = async() => {
        const data = await store.dispatch('fray/cargaUltimosCinco')
        return data
    }

    const leeAnuncio = async(id) => {
        if (!id) return
        
        anuncioDetalle.value = null

        try{
            const { data } =  await publicApi.get(`/tablon-de-anuncios/${id}?sort=id:desc&populate[portada][fields]=url&populate[medias][fields]=url, mime&populate[documentos][fields]=url,caption&pagination[start]=0&pagination[limit]=1`)
            isLoading.value = false
            anuncioDetalle.value = {
                id: data.data.id,
                titulo: data.data.attributes.titulo,
                cuerpo: marked(data.data.attributes.cuerpo),
                portada: (data.data.attributes.portada.data) ? data.data.attributes.portada.data.attributes.url : defaultHeaderImg,
                documentos: (data.data.attributes.documentos) ? data.data.attributes.documentos.data : null,
                multimedia: (data.data.attributes.medias.data) ? data.data.attributes.medias.data.map( m => {
                    return {
                        url: m.attributes.url,
                        mime: m.attributes.mime
                    }
                }) : null
            }
        }catch(error){
            anuncioDetalle.value = null
        }
    }


    const cargaAnuncios = async() => {
        isLoading.value = true
        try{
            
            
            const { data } = await publicApi.get(`tablon-de-anuncios?sort=id:desc&populate[portada][fields]=url&pagination[start]=${inicioAnuncios.value}&pagination[limit]=${finAnuncios.value}&populate[imagenes][fields]=url&populate[documentos][fields]=url,caption`)
            
            const tratadas = data.data.map( n => {
                return {
                id: n.id,
                titulo: n.attributes.titulo,
                cuerpo: marked(n.attributes.cuerpo),
                portada: (n.attributes.portada.data) ? n.attributes.portada.data.attributes.url : defaultImg,
                }
            })
            
            if(primerAnuncio.value === null){
                primerAnuncio.value = tratadas.shift()
            }

            anunciosList.value.push(...tratadas)
            isLoading.value = false
            totalAnuncios.value = data.meta.pagination.total
        }catch (error){
            console.log(error);
        }
    }

    const cargaMas = async () => {
        if (finAnuncios.value >= totalAnuncios.value) return
        inicioAnuncios.value += 5
        finAnuncios.value +=5
        cargaAnuncios()
    }


    return {
        leeAnuncio,
        cargaAnuncios,
        cargaMas,
        anunciosHome,
        anuncio: computed(() => store.state.fray.anuncio),
        recientes: computed(() => store.state.fray.ultimosCinco),
        anuncioDetalle,
        scrollComponent,
        finAnuncios,
        inicioAnuncios,
        primerAnuncio,
        anunciosList,
    }

}


export default useAnuncios