<template>
<div>
    <div class="aa-sec-noticias">
        <span class="aa-text-h1 text-start d-block text-uppercase p-1">
            Anuncio
        </span>
        <span class="aa-sec-noticias-line"></span>
    </div> 

    <Destacado :datos="primerAnuncio" v-if="primerAnuncio" :url="'anuncio'" />

    <div class="aa-sec-noticias-list">
        <span class="aa-text-h1 text-start d-block text-uppercase p-1">
            Archivo
        </span>
        <span class="aa-sec-noticias-line"></span>
    </div> 

    <div class='scrolling-component mb-1' ref='scrollComponent'>
		<NoticiaGrid v-for='anuncio in anunciosList' :datos="anuncio" :key="anuncio.id" :url="'anuncio'" class="fade-in" />
	</div>

    <span v-if="isLoading"> Cargando </span>
</div>
</template>

<script>
import useAnuncios from '../composables/useAnuncios'
import { defineAsyncComponent, onMounted, onUnmounted } from "vue";

export default {
components: { 
    NoticiaGrid: defineAsyncComponent( () => import('../components/Prensa/NoticiaGrid.vue')), 
    Destacado: defineAsyncComponent( () => import('../components/Prensa/Destacado.vue')) 
},
setup(){
    const {cargaAnuncios, cargaMas, primerAnuncio, anunciosList, isLoading, scrollComponent } = useAnuncios()
    
    cargaAnuncios()
    
    onMounted(() => {
        window.addEventListener("scroll", handleScroll)
    })

    onUnmounted(() => {
        window.removeEventListener("scroll", handleScroll)
    })
    

    const handleScroll = () => {
        let element = scrollComponent.value;
        if (element.getBoundingClientRect().bottom < window.innerHeight) {
            cargaMas()
        }
    }
    return {
        primerAnuncio,
        anunciosList, 
        isLoading,
        scrollComponent,
        cargaMas
    }
}

}
</script>

<style lang="sass" scoped>
.aa-sec-noticias, .aa-sec-noticias-list
    position: relative
    margin-top: 96px
    $root:&
    &-line
        background-color: #86e4b9
        display: inline-block
        height: 25px
        left: 0
        position: absolute
        top: 34px
        width: 163px
        z-index: -1

.aa-sec-noticias-list
    margin-top: 0px


</style>